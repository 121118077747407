import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ArchiveItem = ({mobile, el}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {
        mobile
          ? (
            <>
              <div className="col" onClick={() => setOpen(!open)}>
                <div style={{ fontSize: '.7em' }}>{(new Date(el.date)).toISOString().split('T')[0]}</div>
                <div>{el.from.name}</div>
              </div>
              <div className="col-4 text-end">{el.value} {el.currency}</div>
              {
                open
                ? (
                  <div className="col-12 border-top pt-2 mt-2" style={{fontSize: '.97em'}}>
                    <div className="row">
                      <div className="col-5">
                        <div>{el.from.num}</div>
                        <div>{el.from.name}</div>
                      </div>
                      <div className="col-2 d-flex">{'>>'}</div>
                      <div className="col-5 text-end">
                        <div>{el.to.num}</div>
                        <div>{el.to.name}</div>
                      </div>
                    </div>
                  </div>
                )
                : ''
              }
            </>
          )
          : (
            <>
              <div className="col">{(new Date(el.date)).toISOString().split('T')[0]}</div>
              <div className="col">{el.from.num} <br /> {el.from.name}</div>
              <div className="col">{el.to.num} <br /> {el.to.name}</div>
              <div className="col">{el.value} {el.currency}</div>
            </>
          )
      }
    </>
  );
};

const Archive = () => {
  const user = useSelector(state => state.permSetting.currentUser);
  const userArchive = useSelector(state => state.history.history.filter(data => (data.from.type === 'user' && data.from.id === user.id) || (data.to.type === 'user' && data.to.id === user.id)));
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  return (
    <div className='container-fluid'>
      <ul className="list-group">
        {
          userArchive.map(el => (
            <li className="list-group-item">
              <div className="row">
                <ArchiveItem mobile={mobile} el={el}></ArchiveItem>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default Archive;
